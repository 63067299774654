import React from "react";
import ReactDOMClient from "react-dom/client";
import Routes from "./routes/routes";
import LayoutMain from "./components/layouts/layoutMain";
import { BrowserRouter } from "react-router-dom";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
  <BrowserRouter>
    <LayoutMain>
      <Routes />
    </LayoutMain>
  </BrowserRouter>
);
