/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconPlanning = ({ color = "#EA7526", className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-planning ${className}`}
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2552_16811)">
        <path
          className="path"
          d="M47.2479 6.71074C42.2338 2.99684 36.2834 1.03453 30.0403 1.03453C24.3475 1.03453 18.692 2.67316 14.4827 5.48484V1.03453C14.4827 0.463477 14.0192 0 13.4482 0C12.8771 0 12.4136 0.463477 12.4136 1.03453V7.54242C12.4136 7.94379 12.6453 8.30894 13.0085 8.47863C13.3715 8.64937 13.8009 8.59453 14.1092 8.33789C18.0547 5.06063 24.0103 3.10336 30.0403 3.10336C35.8365 3.10336 41.3606 4.92609 46.0168 8.3741C46.202 8.51062 46.4171 8.57684 46.6313 8.57684C46.9479 8.57684 47.2603 8.43199 47.463 8.15789C47.8034 7.69863 47.7072 7.05105 47.2479 6.71074Z"
          fill={color}
        />
        <path
          className="path"
          d="M58.9654 12.4138H52.4575C52.0562 12.4138 51.693 12.6466 51.5224 13.0097C51.3516 13.3728 51.4065 13.8021 51.6631 14.1105C54.9404 18.055 56.8976 24.0105 56.8976 30.0415C56.8976 35.8377 55.0749 41.3618 51.6269 46.017C51.2866 46.4762 51.3828 47.1238 51.8421 47.4643C52.0272 47.6008 52.2424 47.667 52.4566 47.667C52.7731 47.667 53.0855 47.5221 53.2893 47.248C57.0031 42.2339 58.9655 36.2835 58.9655 30.0405C58.9655 24.3477 57.3269 18.6912 54.5152 14.4829H58.9655C59.5366 14.4829 60.0001 14.0194 60.0001 13.4484C59.9999 12.8773 59.5365 12.4138 58.9654 12.4138Z"
          fill={color}
        />
        <path
          className="path"
          d="M46.9925 51.5224C46.6294 51.3527 46.2011 51.4054 45.8917 51.6631C41.9463 54.9404 35.9907 56.8976 29.9607 56.8976C24.1634 56.8976 18.6393 55.0749 13.9841 51.6269C13.5249 51.2866 12.8773 51.3828 12.5369 51.8421C12.1966 52.3013 12.2928 52.9489 12.752 53.2893C17.7661 57.0031 23.7166 58.9655 29.9596 58.9655C35.6523 58.9655 41.3079 57.3269 45.5172 54.5152V58.9655C45.5172 59.5366 45.9807 60.0001 46.5517 60.0001C47.1228 60.0001 47.5863 59.5366 47.5863 58.9655V52.4576C47.5863 52.0562 47.3546 51.691 46.9925 51.5224Z"
          fill={color}
        />
        <path
          className="path"
          d="M8.33789 45.8907C5.06063 41.9452 3.10336 35.9907 3.10336 29.9596C3.10336 24.1634 4.92609 18.6393 8.37305 13.9842C8.71336 13.5249 8.61715 12.8773 8.15789 12.5369C7.69863 12.1955 7.05105 12.2928 6.71063 12.7521C2.99684 17.7662 1.03453 23.7165 1.03453 29.9596C1.03453 35.6524 2.67316 41.308 5.48484 45.5172H1.03453C0.463477 45.5172 0 45.9807 0 46.5518C0 47.1228 0.463477 47.5863 1.03453 47.5863H7.54242C7.94379 47.5863 8.30789 47.3546 8.47863 46.9914C8.64937 46.6283 8.59453 46.1989 8.33789 45.8907Z"
          fill={color}
        />
        <path
          className="path"
          d="M31.0345 19.653C31.0335 19.082 30.57 18.6206 30 18.6206C30 18.6206 29.9989 18.6206 29.9979 18.6206C29.4258 18.6217 28.9644 19.0861 28.9655 19.6571L28.9903 30.002C28.9914 30.573 29.4548 31.0344 30.0248 31.0344C30.0259 31.0344 30.0269 31.0344 30.0269 31.0344C30.5991 31.0333 31.0604 30.5689 31.0594 29.9979L31.0345 19.653Z"
          fill={color}
        />
        <path
          className="path"
          d="M30.0248 28.9655H23.7931C23.2221 28.9655 22.7586 29.4289 22.7586 30C22.7586 30.571 23.2221 31.0345 23.7931 31.0345H30.0248C30.5959 31.0345 31.0593 30.571 31.0593 30C31.0593 29.4289 30.5959 28.9655 30.0248 28.9655Z"
          fill={color}
        />
        <path
          className="path"
          d="M30 8.27588C18.0217 8.27588 8.27594 18.0207 8.27594 30C8.27594 41.9792 18.0217 51.7241 30 51.7241C41.9793 51.7241 51.7241 41.9792 51.7241 30C51.7241 18.0207 41.9793 8.27588 30 8.27588ZM30 49.6551C19.1617 49.6551 10.3449 40.8383 10.3449 30C10.3449 19.1617 19.1617 10.3448 30 10.3448C40.8384 10.3448 49.6552 19.1617 49.6552 30C49.6552 40.8383 40.8384 49.6551 30 49.6551Z"
          fill={color}
        />
        <path
          className="path"
          d="M15.5172 28.9655H13.4483C12.8772 28.9655 12.4138 29.4289 12.4138 30C12.4138 30.571 12.8772 31.0345 13.4483 31.0345H15.5172C16.0883 31.0345 16.5518 30.571 16.5518 30C16.5518 29.4289 16.0883 28.9655 15.5172 28.9655Z"
          fill={color}
        />
        <path
          className="path"
          d="M46.5516 28.9655H44.4827C43.9116 28.9655 43.4481 29.4289 43.4481 30C43.4481 30.571 43.9116 31.0345 44.4827 31.0345H46.5516C47.1227 31.0345 47.5861 30.571 47.5861 30C47.5861 29.4289 47.1227 28.9655 46.5516 28.9655Z"
          fill={color}
        />
        <path
          className="path"
          d="M30 43.4482C29.4289 43.4482 28.9655 43.9117 28.9655 44.4828V46.5517C28.9655 47.1228 29.4289 47.5863 30 47.5863C30.571 47.5863 31.0345 47.1228 31.0345 46.5517V44.4828C31.0345 43.9116 30.571 43.4482 30 43.4482Z"
          fill={color}
        />
        <path
          className="path"
          d="M30 12.4138C29.4289 12.4138 28.9655 12.8773 28.9655 13.4484V15.5173C28.9655 16.0884 29.4289 16.5518 30 16.5518C30.571 16.5518 31.0345 16.0884 31.0345 15.5173V13.4484C31.0345 12.8773 30.571 12.4138 30 12.4138Z"
          fill={color}
        />
        <path
          className="path"
          d="M20.3865 39.6135C19.983 39.2089 19.3282 39.2089 18.9238 39.6135L17.5645 40.9718C17.161 41.3753 17.161 42.0301 17.5645 42.4345C17.7662 42.6362 18.031 42.7376 18.2959 42.7376C18.5607 42.7376 18.8256 42.6373 19.0272 42.4345L20.3865 41.0762C20.79 40.6727 20.79 40.0179 20.3865 39.6135Z"
          fill={color}
        />
        <path
          className="path"
          d="M42.4345 17.5655C42.03 17.161 41.3762 17.161 40.9718 17.5655L39.6135 18.9238C39.2089 19.3283 39.2089 19.9821 39.6135 20.3865C39.8151 20.5882 40.08 20.6896 40.3448 20.6896C40.6097 20.6896 40.8745 20.5882 41.0762 20.3865L42.4345 19.0282C42.8389 18.6238 42.8389 17.97 42.4345 17.5655Z"
          fill={color}
        />
        <path
          className="path"
          d="M42.4345 40.9717L41.0762 39.6133C40.6717 39.2088 40.0179 39.2088 39.6135 39.6133C39.2089 40.0179 39.2089 40.6717 39.6135 41.0761L40.9718 42.4344C41.1735 42.6361 41.4383 42.7374 41.7031 42.7374C41.968 42.7374 42.2328 42.6371 42.4345 42.4344C42.8389 42.03 42.8389 41.3762 42.4345 40.9717Z"
          fill={color}
        />
        <path
          className="path"
          d="M20.3865 18.9238L19.0272 17.5655C18.6238 17.161 17.9689 17.161 17.5645 17.5655C17.16 17.97 17.16 18.6247 17.5645 19.0282L18.9238 20.3865C19.1254 20.5882 19.3903 20.6896 19.6551 20.6896C19.92 20.6896 20.1848 20.5882 20.3865 20.3865C20.791 19.9821 20.791 19.3273 20.3865 18.9238Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2552_16811">
          <rect className="rect" fill="white" height="60" width="60" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconPlanning.propTypes = {
  color: PropTypes.string,
};
