import React from "react";
import "./style.css";

import { Button } from "../../components/Button";
import { BulletCheck7 } from "../../icons/BulletCheck7";
import { Icon1 } from "../../icons/Icon1";
import { IconPlanning } from "../../icons/IconPlanning";
import { IconSpeed } from "../../icons/IconSpeed";
import rectangle7 from "../../../static/img/rectangle-7.svg";
import rectangle5 from "../../../static/img/rectangle-5.svg";
import maskGroup from "../../../static/img/mask-group.png";
import image2 from "../../../static/img/image-2.png";
import backgroundBottom from "../../../static/img/background-bottom.svg";
import backgroundBottom1 from "../../../static/img/background-bottom-1.svg";
import arabesco from "../../../static/img/arabesco.png";
import image1 from "../../../static/img/image-1.png";
import fotoRogerio from "../../../static/img/foto-roge-rio.png";
import fotoMarcelo from "../../../static/img/foto-marcelo.png";
import { ButtonGroup } from "../../components/Button/ButtonApp";

export const LandingPage = (): JSX.Element => {
  return (
    <div id="section-0">
      <div className="div">
        <div className="div-6">
          <div className="overlap-group-2">
            <img className="rectangle" alt="Rectangle" src={rectangle7} />
            <img className="rectangle-2" alt="Rectangle" src={rectangle5} />
            <img className="mask-group" alt="Mask group" src={maskGroup} />

            <div className="text-block">
              <div className="text-5">
                <p className="cuidado-que-evolui">
                  Cuidado que evolui com você
                </p>
                {
                  <p className="text-wrapper-21">
                    Nossa plataforma completa se integra à sua vida, aumentando
                    sua consciência e autonomia. Promovemos uma jornada de
                    cuidado preventiva, preditiva e resolutiva. Com
                    recomendações personalizadas, você pode acompanhar sua
                    evolução e ajustar seu caminho para alcançar um bem-estar
                    crescente
                  </p>
                }
                {/* <p className="text-wrapper-21">
                  Um ecossistema de integração entre profissionais de saúde e
                  paciente que potencializa a autonomia, o empoderamento e o
                  protagonismo das pessoas que buscam mais bem-estar.
                </p> */}
              </div>
              <div className="">
                <ButtonGroup />
              </div>
            </div>
            <img className="image-2" alt="Image" src={image2} />
          </div>
        </div>

        <div className="content">
          <img
            className="background-bottom"
            alt="Background bottom"
            src={backgroundBottom}
          />
          <img
            className="img"
            alt="Background bottom"
            src={backgroundBottom1}
          />
          <div className="o-que-fazemos" id="section-4">
            <div className="content-2">
              <p className="text-wrapper">Estar bem é estar Wellny</p>
              <p className="onde-a-paixa-o-por">
                Onde a paixão por tecnologia se uniu a um olhar holístico sobre
                a saúde para incentivar o cuidado preventivo e preditivo.
                <br />
                Nossa Inteligência Artificial caminha ao lado das pessoas,
                funcionando como um organismo vivo e integrado que é capaz de
                aprender e evoluir. Proporcionando recomendações com
                assertividade, ampliamos nossa capacidade de conhecer mais sobre
                nós mesmos.
              </p>
            </div>

            <div className="itens">
              <div className="element">
                <IconPlanning className="icon" color="#EA7526" />
                <p className="text-wrapper-2">
                  Faça testes e descubra seu score
                </p>
                <p className="p">
                  Descubra seu score de saúde com nosso app de bem-estar! De
                  forma rápida e simples, guiaremos você por um conjunto de
                  questões cuidadosamente elaboradas para avaliar sua situação
                  de saúde atual. Basta seguir o passo a passo em nosso
                  aplicativo e, em poucos minutos, você obterá seu resultado. É
                  uma maneira eficiente e conveniente de monitorar seu bem-estar
                  geral. Experimente agora e fique por dentro de como está sua
                  saúde!
                </p>
              </div>
              {
                // <div className="element-2">
                //   <Icon1 className="icon" color="#EA7526" />
                //   <div className="text-wrapper-2">
                //     Encontre o profissional correto
                //   </div>
                //   <p className="p">
                //     Encontre o profissional de saúde ideal com a Wellny! Nossa
                //     plataforma integrada conecta você a especialistas que
                //     oferecem um atendimento diferenciado e personalizado. Com
                //     uma abordagem holística e empática, nossos profissionais
                //     focam na prevenção de saúde e compreendem que os sintomas
                //     clínicos são o resultado de diversos fatores relacionados à
                //     experiência individual. Assim, o tratamento é planejado
                //     considerando você como um todo. Acesse a Wellny e comece sua
                //     jornada para um cuidado de saúde verdadeiramente
                //     personalizado e eficaz.
                //   </p>
                // </div>
              }
              <div className="element-2">
                <IconSpeed className="icon" color="#EA7526" />
                <div className="text-wrapper-2">Veja sua evolução</div>
                <p className="p">
                  Veja sua evolução com nosso app! À medida que você realiza
                  novas avaliações, nosso aplicativo permite que você visualize
                  seu progresso. Acompanhe as recomendações personalizadas
                  baseadas nas informações que você forneceu sobre seus hábitos
                  e outros aspectos relevantes da sua saúde. Com nosso sistema
                  intuitivo, é fácil monitorar as mudanças e ajustar seu caminho
                  para um bem-estar cada vez maior. Experimente e observe sua
                  evolução em tempo real!
                </p>
              </div>
            </div>
          </div>
          <div className="element-3" id="section-1">
            <div className="frame">
              <div className="overlap-group">
                <div className="VERDE" />
                <img className="ARABESCO" alt="Arabesco" src={arabesco} />
              </div>
            </div>
            <div className="text" id="section-1">
              <div className="text-wrapper-3">A Wellny</div>
              <p className="wellny-e-se-encantar">
                Wellny é se encantar por uma tecnologia capaz de abraçar vidas e
                acreditar que as inovações feitas por pessoas devem ser usadas a
                favor das pessoas. Enquanto o mundo segue evoluindo, nós
                expandimos a capacidade de conhecer mais sobre nós mesmos.
                <br />
                <br />
                Na Wellny incentivamos um amanhã onde as pessoas vivam sua
                melhor trajetória, onde olhar para a saúde seja mais leve. Para
                nós, cuidar de uma pessoa que é única significa cuidar do mundo
                inteiro, afinal, impactos positivos geram impactos melhores
                ainda.
              </p>
              <p className="text-wrapper-4">
                Te convidamos a revolucionar sua jornada de saúde. Te convidamos
                a ser Wellny!
              </p>
              <div className="">
                <ButtonGroup />
              </div>
            </div>
          </div>

          <div className="screenshots" id="section-2">
            <div className="element-4">
              <div className="text-2">
                <div className="text-wrapper-5">Seu parceiro de bem-estar</div>
                <p className="text-wrapper-6">
                  Descubra e acompanhe sua saúde com nosso app! De maneira
                  rápida e simples, guiamos você através de questões elaboradas
                  para avaliar sua saúde atual. Siga o passo a passo e obtenha
                  resultados em minutos. Além disso, conforme realiza novas
                  avaliações, você pode visualizar sua evolução e receber
                  recomendações personalizadas baseadas em seus hábitos e outros
                  aspectos relevantes. Nosso sistema intuitivo facilita o
                  monitoramento e ajuste do seu caminho para um bem-estar cada
                  vez maior. Experimente agora e observe sua saúde melhorar em
                  tempo real!
                </p>
              </div>
              <div className="images">
                <img className="image" alt="Image" src={image1} />
                <div className="slider">
                  <div className="element-5" />
                  <div className="element-6" />
                  <div className="element-7" />
                </div>
              </div>
            </div>

            {
              // <div className="element-4" id="section-3">
              //   <div className="text-3">
              //     <div className="text-wrapper-5">Para profissionais</div>
              //     <p className="text-wrapper-6">
              //       Conecte-se com pacientes ideais na Wellny! Nossa plataforma
              //       integrada permite que profissionais de saúde como você
              //       ofereçam um atendimento diferenciado e personalizado. Com
              //       uma abordagem holística e empática, você poderá focar na
              //       prevenção de saúde e entender que os sintomas clínicos são o
              //       resultado de diversos fatores relacionados à experiência
              //       individual do paciente. O tratamento é planejado
              //       considerando o paciente como um todo, permitindo um cuidado
              //       verdadeiramente personalizado e eficaz. Acesse a Wellny e
              //       comece sua jornada para proporcionar um atendimento de saúde
              //       excepcional, guiado e diferenciado.
              //     </p>
              //   </div>
              //   <img className="frame-2" alt="Frame" src="/img/frame-22.svg" />
              // </div>
            }
          </div>

          <div className="frame-3">
            <div className="text-wrapper-5">Founders</div>
            <div className="content-3">
              <div className="div-2">
                <img
                  className="foto-rogerio"
                  alt="Foto rogerio"
                  src={fotoRogerio}
                />
                <div className="text-4">
                  <div className="text-wrapper-9">
                    Dr. Rogério Augusto Queiroz{" "}
                  </div>
                  <p className="fisioterapeuta">
                    Fisioterapeuta graduado pela Pontifícia Universidade
                    Católica de Campinas e Osteopata D.O. pela Escola de
                    Osteopatia Madrid / S.E.F.O.
                    <br />
                    Especialista em Educação pela UNICAMP e mestre em Anatomia
                    pela Universidade de Cádiz, na Espanha. Possui MBA em Gestão
                    e Empreendedorismo pela Metrocamp/DeVry. É editor
                    internacional do Journal of American Osteopathic
                    Association, Presidente da Osteopatia Sem Fronteiras (OSF),
                    CEO da Escuela de Osteopatía de Madrid Brasil
                  </p>
                </div>
              </div>
              <div className="div-2">
                <div className="text-4">
                  <div className="text-wrapper-10">Marcelo Rosa</div>
                  <p className="fisioterapeuta-2">
                    Fundador e CEO da Kognit, cofundador e CTO da Veridex.{" "}
                    <br />
                    Investidor e Board Member em startups dos segmentos de
                    Cibersegurança, Finanças, Automotivo e Saúde. <br />
                    +30 anos de experiência estratégica em Tecnologia
                  </p>
                </div>
                <img
                  className="foto-marcelo"
                  alt="Foto marcelo"
                  src={fotoMarcelo}
                />
              </div>
            </div>
          </div>

          {/* <div className="pricing-plans" id="section-4">
            <div className="text-wrapper-11">Planos</div>
            <p className="lorem-ipsum-dolor">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum nisi aliquet
              <br />
              volutpat pellentesque volutpat est.
            </p>
            <div className="plans">
              <div className="gratuito">
                <div className="preo">
                  <div className="div-3">Gratuito</div>
                  <div className="frame-4">
                    <p className="div-3">
                      <span className="span">R$</span>
                      <span className="text-wrapper-12">0</span>
                    </p>
                    <div className="text-wrapper-13">por mês</div>
                  </div>
                  <div className="splitter" />
                </div>
                <div className="diferenciais">
                  <div className="text-wrapper-14">Você tem acesso à:</div>
                  <div className="name">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-15">Avaliação momentâneas</div>
                  </div>
                  <div className="name">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-15">Profissionais por teleconsulta</div>
                  </div>
                  <div className="name">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-15">Agendamento com profissionais</div> */}
          {/* <input className="text-wrapper-15" placeholder="Agendamento com profissionais" type="number" /> */}
          {/* </div>
                </div>
              </div>

              <div className="gratuito-2">
                <div className="preo">
                  <div className="div-4">Expert</div>
                  <div className="frame-4">
                    <p className="div-4">
                      <span className="text-wrapper-16">R$</span>
                      <span className="text-wrapper-12">200</span>
                    </p>
                    <div className="text-wrapper-17">por mês</div>
                  </div>
                  <div className="splitter" />
                </div>
                <div className="diferenciais-2">
                  <div className="text-wrapper-18">Você tem acesso à:</div>
                  <div className="name-2">
                    <BulletCheck7 className="bullet-check" />
                    <p className="text-wrapper-20">Tudo do plano Beginner +</p>
                  </div>
                  <div className="name-2">
                    <BulletCheck7 className="bullet-check" />
                    <p className="text-wrapper-20">Gráficos de evolução no tempo</p>
                  </div>
                  <div className="name-2">
                    <BulletCheck7 className="bullet-check" />
                    <p className="text-wrapper-20">Ideias e acompanhamento de metas</p>
                  </div>
                  <div className="name-2">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-20">Desconto em atendimentos</div>
                  </div>
                  <div className="name-2">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-20">Dicas de saúde personalizadas</div>
                  </div>
                </div>
              </div>
              <div className="gratuito-3">
                <div className="preo">
                  <div className="div-3">Beginner</div>
                  <div className="frame-4">
                    <p className="div-3">
                      <span className="span">R$</span>
                      <span className="text-wrapper-12">100</span>
                    </p>
                    <div className="text-wrapper-13">por mês</div>
                  </div>
                  <div className="splitter" />
                </div>
                <div className="diferenciais-3">
                  <div className="text-wrapper-14">Você tem acesso à:</div>
                  <div className="name-3">
                    <BulletCheck7 className="bullet-check" />
                    <p className="text-wrapper-15">Tudo do plano gratuito +</p>
                  </div>
                  <div className="name-3">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-15">Avaliações gravadas</div>
                  </div>
                  <div className="name-3">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-15">Comparações entre períodos</div>
                  </div>
                  <div className="name-3">
                    <BulletCheck7 className="bullet-check" />
                    <div className="text-wrapper-15">Promoções feitas pelos profissionais</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
