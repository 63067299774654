/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconSpeed = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-speed ${className}`}
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3010_257)">
        <path
          className="path"
          d="M19.0635 23.3911C18.9035 23.0772 18.5905 22.8711 18.2396 22.8482L9.82722 22.2872C9.39129 22.2442 8.98829 22.5142 8.82926 22.9201L2.76475 38.419C2.60175 38.835 2.73475 39.3079 3.09077 39.5789C3.26877 39.7148 3.48275 39.7829 3.69568 39.7829C3.90662 39.7829 4.11767 39.7169 4.29568 39.583L9.3293 35.8083L12.2601 36.3942C12.7381 36.4893 13.216 36.2272 13.39 35.7703C14.964 31.6476 16.8588 27.8139 19.0196 24.3772C19.2066 24.079 19.2236 23.704 19.0635 23.3911ZM11.8281 34.2682L9.28231 33.7593C9.00434 33.7043 8.7143 33.7703 8.48638 33.9402L5.90757 35.874L10.4242 24.3309L16.4617 24.7329C14.7219 27.6778 13.168 30.8754 11.8281 34.2682Z"
          fill={color}
        />
        <path
          className="path"
          d="M37.7131 50.174L37.1521 41.7606C37.1281 41.4086 36.9221 41.0957 36.6082 40.9356C36.2952 40.7757 35.9202 40.7916 35.6223 40.9796C32.1845 43.1404 28.3518 45.0353 24.2292 46.6091C23.7732 46.7821 23.5092 47.2601 23.6053 47.739L24.1912 50.6698L20.4165 55.7024C20.1485 56.0604 20.1496 56.5524 20.4205 56.9084C20.6135 57.1623 20.9105 57.3033 21.2164 57.3033C21.3384 57.3033 21.4615 57.2803 21.5814 57.2363L37.0802 51.1717C37.4861 51.013 37.7421 50.6089 37.7131 50.174ZM24.1251 54.0907L26.0589 51.5119C26.229 51.2849 26.2949 50.995 26.2389 50.716L25.73 48.1712C29.1237 46.8313 32.3204 45.2774 35.2652 43.5375L35.6682 49.574L24.1251 54.0907Z"
          fill={color}
        />
        <path
          className="path"
          d="M28.9978 30.0006H22.2602C21.8973 30.0006 21.5633 30.1976 21.3863 30.5146L12.9299 45.7094C12.7099 46.1054 12.7839 46.6013 13.1099 46.9154C13.3009 47.0994 13.5518 47.1954 13.8038 47.1954C13.9819 47.1954 14.1608 47.1484 14.3208 47.0504L29.5156 37.8551C29.8145 37.6742 29.9976 37.3502 29.9976 37.0003V31.0006C29.9977 30.4486 29.5497 30.0006 28.9978 30.0006ZM27.9979 36.4372L16.5098 43.3896L22.8474 32.0015V32.0004H27.9979V36.4372Z"
          fill={color}
        />
        <path
          className="path"
          d="M59.9495 0.96377C59.9085 0.476861 59.5226 0.0908489 59.0356 0.0498337C58.7036 0.0238183 25.7752 -2.27503 11.5222 35.0562C11.3813 35.4252 11.4702 35.8411 11.7492 36.1201L16.5038 40.8747C16.7268 41.0978 17.0388 41.1997 17.3518 41.1577C17.6628 41.1128 17.9348 40.9248 18.0868 40.6497L22.8504 32.0005H27.9981V36.4491L19.2967 41.9427C19.0398 42.1057 18.8708 42.3757 18.8368 42.6777C18.8028 42.9796 18.9087 43.2806 19.1238 43.4956L23.8784 48.2502C24.0694 48.4412 24.3244 48.5432 24.5854 48.5432C24.7054 48.5432 24.8263 48.5222 24.9423 48.4772C62.2753 34.2232 59.9775 1.29482 59.9495 0.96377ZM54.7039 18.7045C51.1951 27.3858 43.1657 39.188 24.8322 46.3764L21.4224 42.9666L29.5318 37.847C29.8217 37.663 29.9977 37.344 29.9977 37.001V31.0014C29.9977 30.4495 29.5497 30.0015 28.9978 30.0015H22.2583C21.8934 30.0015 21.5583 30.2005 21.3823 30.5195L16.9756 38.5208L13.6229 35.1671C26.135 3.24761 52.805 1.94169 57.9896 2.0147C58.0266 4.34659 57.8126 11.012 54.7039 18.7045Z"
          fill={color}
        />
        <path
          className="path"
          d="M17.1286 44.1645C16.9116 43.8766 16.5657 43.7366 16.2266 43.7725C16.2617 43.4326 16.1216 43.0876 15.8346 42.8706C14.3268 41.7307 12.3809 41.3517 10.4941 41.8296C8.49128 42.3385 6.85337 43.7044 6.00142 45.5793L0.0899552 58.5854C-0.0830119 58.9653 -0.00203611 59.4123 0.292922 59.7062C0.483936 59.8982 0.739871 59.9992 0.999907 59.9992C1.13995 59.9992 1.28092 59.9692 1.41393 59.9092L14.42 53.9967C16.2938 53.1458 17.6597 51.5079 18.1686 49.5051C18.6476 47.6202 18.2686 45.6734 17.1286 44.1645ZM16.2307 49.0131C15.8727 50.424 14.9109 51.5769 13.593 52.1769L3.01376 56.9855L7.82238 46.4054C8.42132 45.0875 9.57525 44.1255 10.9862 43.7676C11.3572 43.6736 11.7301 43.6276 12.098 43.6276C12.721 43.6276 13.3299 43.7606 13.8849 44.0196L12.933 45.7045C12.712 46.0954 12.779 46.5864 13.0969 46.9034C13.4149 47.2224 13.9059 47.2884 14.2959 47.0673L15.9808 46.1144C16.3907 46.9963 16.4837 48.0151 16.2307 49.0131Z"
          fill={color}
        />
        <path
          className="path"
          d="M59.9495 0.964767C59.9085 0.477857 59.5224 0.0918456 59.0355 0.0508304C58.6455 0.019776 49.3762 -0.682171 38.4751 4.3395C38.1781 4.47544 37.9671 4.74942 37.9091 5.07144C37.851 5.39347 37.9551 5.72335 38.1861 5.95433L54.0449 21.8132C54.2339 22.0022 54.4889 22.1061 54.7519 22.1061C54.8109 22.1061 54.8699 22.1011 54.9289 22.0911C55.2509 22.0331 55.5238 21.8222 55.6608 21.5251C60.6834 10.624 59.9815 1.35371 59.9495 0.964767ZM54.4279 19.3663L40.6329 5.57136C48.6133 2.20261 55.6017 1.97058 57.9906 2.01065C58.0306 4.40149 57.8055 11.368 54.4279 19.3663Z"
          fill={color}
        />
        <path
          className="path"
          d="M44.1606 15.8387C40.7978 12.476 35.3274 12.476 31.9646 15.8387C28.6018 19.2015 28.6018 24.672 31.9646 28.0348C33.6455 29.7166 35.8543 30.5566 38.0621 30.5566C40.271 30.5566 42.4788 29.7157 44.1606 28.0348C47.5234 24.672 47.5234 19.2014 44.1606 15.8387ZM42.7468 26.6208C40.164 29.2036 35.9613 29.2036 33.3785 26.6208C30.7958 24.038 30.7958 19.8354 33.3785 17.2526C34.6695 15.9617 36.3663 15.3157 38.0622 15.3157C39.7582 15.3157 41.455 15.9617 42.7469 17.2526C45.3296 19.8354 45.3296 24.038 42.7468 26.6208Z"
          fill={color}
        />
        <path
          className="path"
          d="M40.05 23.925C38.9541 25.0229 37.1703 25.0219 36.0733 23.925C34.9773 22.8281 34.9773 21.0453 36.0733 19.9483C37.1692 18.8523 38.953 18.8514 40.05 19.9483C40.44 20.3392 41.074 20.3392 41.4638 19.9483C41.8548 19.5573 41.8548 18.9253 41.4638 18.5344C39.589 16.6586 36.5352 16.6586 34.6594 18.5344C32.7835 20.4102 32.7835 23.463 34.6594 25.3388C35.5974 26.2768 36.8303 26.7458 38.0622 26.7458C39.2951 26.7458 40.5269 26.2768 41.4638 25.3388C41.8548 24.9479 41.8548 24.3159 41.4638 23.925C41.0738 23.5341 40.44 23.5341 40.05 23.925Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_3010_257">
          <rect className="rect" fill="white" height="60" width="60" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconSpeed.propTypes = {
  color: PropTypes.string,
};
