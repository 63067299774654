import React from "react";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes as RoutesWrapper,
} from "react-router-dom";

import { LandingPage } from "../screens/LandingPage";
import { AppReferences } from "../screens/AppReferences/AppReferences";
import { PrivacyTerms } from "../screens/PrivacyTerms/PrivacyTerms";
import { RoutesConstants } from "./routesConstants";
import { TermsOfUse } from "../screens/TermsOfUse/TermsOfUse";

const Routes: React.FC = () => {
  return (
    <RoutesWrapper>
      <Route path="*" element={<LandingPage />} />
      <Route path={RoutesConstants.appReferences} element={<AppReferences />} />
      <Route path={RoutesConstants.privacyTerms} element={<PrivacyTerms />} />
      <Route path={RoutesConstants.termsOfUse} element={<TermsOfUse />} />
    </RoutesWrapper>
  );
};

export default Routes;
