import React, { useState, useEffect } from "react";
import { MenuItem } from "../MenuItem/MenuItem";
import { RoutesConstants } from "../../routes/routesConstants";
import "./style.css";
import { Link } from "react-router-dom";
import logo from "../../../static/img/logo.png";
import iconLinkedIn from "../../../static/img/icon-linkedin.svg";
import iconInstagram from "../../../static/img/icon-instagram.svg";

const menuItems = [
  "Diferenciais",
  "Quem somos",
  "Aplicativo",
  // "Profissionais",
  // "Planos",
];

export const Footer: React.FC = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      // Verifica se a tela foi rolada para cima ou para baixo
      setMenuVisible(
        currentScrollPosition <= lastScrollPosition ||
        currentScrollPosition === 0
      );
      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  useEffect(() => {
    const targetElement = document.getElementById(`section-${activeSection}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeSection]);

  return (
    // <div className={`menu ${isMenuVisible ? 'visible' : 'hidden'}`}>
    <div className="containerFooter">
      <footer className="footer">
        <img className="logo" alt="Logo" src={logo} />

        <div>
          <div className="div-7">
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                text={item}
                onClick={() => setActiveSection(index)}
              />
            ))}
          </div>
        </div>
        <div className="box-SocialButtons">
          <a href="https://www.linkedin.com/company/wellny-oficial/">
            <img
              className="social-buttons"
              alt="Social buttons"
              src={iconLinkedIn}
            />
          </a>
          <a href="https://www.instagram.com/wellny_br/">
            <img
              className="social-buttons"
              alt="Social buttons"
              src={iconInstagram}
            />
          </a>
        </div>
      </footer>
      <div className="containerCopyright">
        <div className="copyrightLinks">
          <Link to={RoutesConstants.termsOfUse}>Termos de Uso</Link>
          <Link to={RoutesConstants.privacyTerms}>Política de Privacidade</Link>
        </div>
        <p className="copyrightText">
          Direitos Autorais © 2024 Wellny Todos os Direitos Reservados.
        </p>
      </div>
    </div>
  );
};
