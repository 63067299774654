import React from "react";
import "./style.css";
import { strict } from "assert";

interface MenuItemProps {
  text: string;
  onClick: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ text, onClick }) => {
  return (
    <div className="text-wrapper-15" onClick={onClick}>
      {text}
    </div>
  );
};
